<template>
  <div class="content">
    <div class="main">
      <div class="main-r">
        <div class="div1">
          <div class="div1-1">比价列表</div>
        </div>
        <div class="div2">
          <span class="span1">比价单号</span>
          <span class="span2">比价产品</span>
          <span class="span3">发布时间</span>
          <span class="span4">比价服务商</span>
          <div class="status">
            <el-dropdown placement="bottom-start" @command="handleCommand">
              <span class="el-dropdown-link">
                {{info2}}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="比价中">比价中</el-dropdown-item>
                <el-dropdown-item command="已完成">已完成</el-dropdown-item>
                <el-dropdown-item command="已关闭">已关闭</el-dropdown-item>
                <el-dropdown-item command="全部状态">全部状态</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <span class="span6">操作</span>
        </div>

        <ul class="ul1" v-for="(item,i) in list" :key="i">
          <li>
            <span class="span1">{{item.inviteId}}</span>
            <span class="span2">{{item.productName}}</span>
            <span class="span3">{{item.createDate}}</span>
            <span class="span4">{{item.storeName}}等{{item.state}}家</span>
            <span class="span5">{{item.state | compareStatus}}</span>

            <span class="span6">
              <router-link :to="'/priceDetail/'+item.inviteId" tag="span" style="color:#0db168">查看</router-link>
            </span>
          </li>
          <!--  -->
        </ul>

        <div class="pagination">
          <div class="block">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[2,4,8]"
              :page-size="8"
              layout="total, sizes, prev, pager, next, jumper"
              :total="totalSize"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from '@/api/api'
import Vue from 'vue'
import Aside from '@/components/common/aside.vue'

export default {
  name: 'index',
  components: { Aside }, //注册组件
  data() {
    return {
      radio1: '上海',
      radio2: '上海',
      num1: 5,
      show: true,
      shows: false,
      info: '20',
      activeName: '',
      info1: '全部购买订单',
      flagSelect: false,
      info2: '比价状态',

      currentPage: 1, //前往第几页默认展示去往第一页，在结构中需绑定
      list: [],
      id: 0,
      page: 1, //刷新页面，展示第一页，每页8行
      rows: 10,
      totalSize: 0, //一共几条数据
      state: 3 //默认显示所有注册状态
    }
  },
  methods: {
    showCont() {
      this.show == true, (this.shows = false)
    },
    showConts() {
      this.show == false, (this.shows = true)
    },
    handleClick() {},
    btn1() {
      this.flagSelect = true
    },
    handleSizeChange(val) {
      //分页
      this.rows = `${val}` //每页多少条
      let self = this
      let searchParams = { page: self.page, rows: self.rows, state: self.state }
      api.myPrice(searchParams).then(res => {
        //上方数据传进myneeds这个api内的params
        self.list = res.data.data.resData
      })
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange(val) {
      this.page = `${val}` //一共几页
      let self = this
      let searchParams = { page: self.page, rows: self.rows, state: self.state }
      api.myPrice(searchParams).then(res => {
        self.list = res.data.data.resData
      })
      console.log(`当前页: ${val}`)
    },
    priceDetail() {
      this.$router.push('/priceDetail') //调转到比价详情
    },
    handleCommand(command) {
      //点击下拉列表，变换头部内容
      this.info2 = command
      if (command === '未报价') {
        this.state = 0
      }
      if (command === '比价中') {
        this.state = 1
      }
      if (command === '已关闭') {
        this.state = 2
      }
      if (command === '全部状态') {
        this.state = 3
      }
      this.page = 1
      this.myPrice()
    },

    myPrice() {
      const searchParams = { page: this.page, rows: this.rows, state: this.state }
      api.myPrice(searchParams).then(res => {
        this.list = res.data.data.resData
        this.totalSize = res.data.data.totalSize //共几条数据
      })
    }
  },
  mounted() {
    this.myPrice()
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/common';
* {
  margin: 0;
  padding: 0;
}
h2,
ul,
li {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}
li {
  list-style: none;
  border-bottom: 1px solid #e5e5e5;
}
//............以下是下拉菜单样式........... //
.el-dropdown-menu {
  width: 95px;
  text-align: center;
  top: 360px !important;
  font-size: 16px;
  margin-top: 40px;
}
.el-dropdown {
  width: 100px;
  text-align: center;
  cursor: pointer !important;
}

//............以上是下拉菜单样式........... //
.content {
  width: 100%;
  // height: 1192px;
  margin-bottom: 30px;
  background: #f5f5f5;
  font-size: 15px;
  /deep/ .main {
    width: 1200px;
    height: 1129px;
    margin: 0 auto;
    font-size: 15px;
    .div1 {
      overflow: hidden;

      .div1-1 {
        margin: 20px;
        .el-tabs__header {
          margin: 0 !important;
        }
      }
      .ul-top {
        width: 100%;
        height: 43px;
        border-bottom: 1px solid #e5e5e5;
        li {
          float: left;
          line-height: 43px;
          padding: 0 20px;
        }
      }
    }

    .main-l {
      width: 181px;
      height: 1129px;
      background: #fff;
      float: left;
    }
    /deep/ .main-r {
      width: 1000px;
      height: 100%;
      background: #fff;

      .el-tabs__nav-scroll {
        margin-top: 20px 0;
      }
      .el-button--primary {
        width: 134px;
        height: 30px;
        border: 1px solid #e5e5e5;
      }
      .ipt1 {
        width: 193px;
        height: 30px;
        border: 1px solid #e5e5e5;
        margin-left: 30px;
        padding-left: 12px;
        color: #999;
      }
      .btn1 {
        width: 46px;
        height: 30px;
        margin-left: 18px;
        background: #0db168;
        border: 0;
        color: #fff;
        border-radius: 2px;
      }
      .div2 {
        width: 962px;
        height: 50px;
        font-size: 16px;
        line-height: 16px;
        margin: 0 auto;
        height: 43px;
        background: #f5f5f5;
        color: #666;
        border: 1px solid #e5e5e5;
        line-height: 44px;
        font-size: 16px;
        .span1 {
          display: block;
          width: 145px;
          margin-left: 20px;
          text-align: center;
          float: left;
        }
        .span2 {
          display: block;
          width: 225px;
          overflow: hidden;
          margin-left: 14px;
          height: 72px;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: center;
          float: left;
        }
        .span3 {
          display: block;
          width: 82px;
          margin-left: 14px;
          text-align: center;
          float: left;
        }
        .span4 {
          width: 113px;
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: center;
          margin: 0 0 0 45px;
          margin-left: 60px;
          float: left;
        }
        .span5 {
          display: block;
          width: 102px;
          height: 72px;
          margin-left: 35px;
          text-align: center;
          float: left;
        }
        .status {
          display: flex;
          margin-left: 33px;
          justify-content: center;

          width: 130px;
          float: left;
          .el-dropdown-link {
            font-size: 16px !important;
          }
          .status-icon {
            margin-left: 3px;
            cursor: pointer;
          }
        }
        .span6 {
          display: block;
          float: left;
          width: 95px;
          text-align: center;
        }

        .span1,
        .span2,
        .span3,
        .span4,
        .span5,
        .span6 {
          display: block;
          text-align: center;
          float: left;
        }
      }
      .ul1 {
        width: 962px;
        max-height: 800px;
        overflow: hidden;
        margin: 0 auto;
        margin-top: 20px;
        border: 1px solid #e5e5e5;
        border-bottom: 0;

        li {
          height: 72px;
          line-height: 72px;
          > span {
            overflow: hidden;
          }
          .span1 {
            float: left;
            width: 145px;
            margin-left: 20px;
          }
          .span2 {
            width: 225px;
            margin-left: 11px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: center;
            float: left;
          }
          .span3 {
            display: block;
            width: 113px;
            text-align: center;
            float: left;
            line-height: 28px;
            margin-top: 8px;
          }
          .span4 {
            width: 113px;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: center;
            margin: 0 45px;

            float: left;
          }
          .span5 {
            display: block;
            width: 102px;
            text-align: center;
            float: left;
          }
          .span6 {
            span {
              cursor: pointer;
            }
            display: block;
            width: 130px;
            text-align: center;
            float: left;
          }
        }
      }

      .pagination {
        width: 800px;
        height: 40px;
        margin: 0 auto;
        text-align: center;
        margin-top: 60px;
      }
      .div3 {
        padding-left: 30px;
      }
      .div4 {
        width: 960px;
        border: 1px solid #e5e5e5;
        margin: 0 auto;
        .div4-1 {
          width: 960px;
          height: 44px;
          background: #f5f5f5;
          margin: 0 auto;
          font-size: 15px;
          line-height: 44px;
          color: #656565;
        }
        .ul2 {
          margin: 0 auto;
          width: 960px;
          height: 120px;

          li {
            width: 960px;
            height: 120px;

            .ul2-lil {
              width: 327px;
              height: 100%;
              float: left;
            }
            .ul2-lir {
              width: 536px;
              height: 100%;
              float: right;
            }
          }
        }
      }
      .div5 {
        width: 960px;
        margin: 0 auto;
        height: 94px;
        line-height: 94px;
        text-align: right;
        span {
          margin-right: 30px;
        }
        .teshu {
          color: #fe0036;
        }
        .btn1 {
          width: 100px;
          height: 36px;

          border-radius: 3px;
          border: none;
          font-size: 15px;
          color: #fff;
        }
      }
    }
  }
}
</style>
